.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading h5 {
  text-align: center;
  text-transform: uppercase;
}
h4,
h6 {
  text-align: center;
}
h4 {
  margin-top: 20px;
}
h6 {
  margin-bottom: 20px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader {
  width: 100px;
  height: 100px;
  margin: auto;
}
.grouptitle {
  border-bottom: 1px solid rgba(0,0,0,0.125);
  font-size: 2em;
  font-weight: 350;
}
/*# sourceMappingURL=src/pages/ondemand/onwardtravel/results.css.map */