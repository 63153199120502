.search-form {
  position: relative;
}
.code {
  height: 25vh;
  width: 100%;
  font-size: 8vmax;
  color: rgba(255,255,255,0.7);
  overflow: hidden;
  background-image: url("~assets/backgrounds/onwardtravel.jpg");
  background-position: bottom;
  background-size: cover;
  text-overflow: clip;
}
.column.container {
  margin-top: -64px;
}
.q-card {
  width: 80vw;
  max-width: 600px;
}
/*# sourceMappingURL=src/pages/ondemand/onwardtravel/index.css.map */