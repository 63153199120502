.vehicle-card {
  z-index: 100;
  position: relative;
}
.detail {
  font-size: 14px;
}
.vehicle {
  cursor: pointer;
}
.btn-price {
  color: #1e88e5;
  border-color: #1e88e5;
}
.vehicle:hover .btn-price {
  background: #1e88e5 !important;
  border-color: #1e88e5;
  color: #fff;
}
.q-item {
  min-height: 100px;
}
.q-item__label {
  z-index: 200;
  text-shadow: 0px 0px 2px #fff;
}
/*# sourceMappingURL=src/pages/ondemand/onwardtravel/vehicle-card.css.map */